.sidebar {
  width: 220px;
  height: 100%;
  border-radius: var(--border_radius);
  overflow: hidden;
  position: fixed;
  top: 1rem;
  -webkit-transition: 0.3s left ease-in-out;
  -o-transition: 0.3s left ease-in-out;
  transition: 0.3s left ease-in-out;
  height: calc(100vh - 2rem);
}

.sidebar.toggleShow {
  left: 1rem;
}

.sidebar.toggleHide {
  left: -280px;
}

.sidebar .c_card {
  height: 100%;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  overflow-y: scroll;
}

.sidebar .c_card::-webkit-scrollbar {
  display: none;
}

.sidebar .sidebar_header {
  margin-bottom: 1rem;
}

.sidebar .sidebar_cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 5px;
}

.sidebar .link {
  padding: 8px 14px;
  cursor: pointer;
  display: block;
  position: relative;
  color: var(--body_text);
  font-weight: 500;
  font-size: 15px;
}

.sidebar .link.active::before {
  content: "";
  position: absolute;
  width: 4px;
  background-color: var(--primary);
  height: 100%;
  top: 0;
  left: 0;
}

.sidebar .link.active {
  color: var(--primary);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgb(37 99 235 / 10%)),
    to(rgb(37 99 235 / 0%))
  );
  background: -o-linear-gradient(
    left,
    rgb(37 99 235 / 10%) 0%,
    rgb(37 99 235 / 0%) 100%
  );
  background: linear-gradient(
    90deg,
    rgb(37 99 235 / 10%) 0%,
    rgb(37 99 235 / 0%) 100%
  );
}
