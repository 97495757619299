header.c_card {
  padding: 8px 14px;
}

.header_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger .bi::before {
  font-size: 1.5rem;
  font-weight: 800 !important;
  cursor: pointer;
  vertical-align: middle;
}

.header_right {
  column-gap: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.avatar {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.lang_select_wrapper {
  display: flex;
  align-items: center;
  gap: 0px;
}

.lang_select_wrapper select {
  font-size: 13px;
  border: 0;
}

@media screen and (min-width: 1025px) {
  .hamburger {
    display: none;
  }
}