@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");


:root {
  --primary: #2563EB;
  --main_text: #040404;
  --body_text: #9295a3;
  --primary_gradient: linear-gradient(
    180deg,
    #2563EB 0%,
    rgba(37, 99, 235, 0.35) 100%
  );
  --border_radius: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f3f3f3;
  color: var(--main_text);
}

::-webkit-scrollbar {
  display: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

textarea,
button,
input {
  outline: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}

.c_card {
  padding: 14px;
  background: #ffffff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: var(--border_radius);
}

.c_head {
  font-size: 18px;
  font-weight: 600;
}

.c_input_wrapper {
  margin-bottom: 8px;
}

.c_input_wrapper select,
.c_input_wrapper textarea,
.c_input_wrapper input {
  outline: 0;
  width: 100%;
  border: 0;
  border-radius: 8px;
  background-color: #f3f3f3;
  font-size: 15px;
  padding: 10px;
}

.c_input_wrapper select::placeholder,
.c_input_wrapper textarea::placeholder,
.c_input_wrapper input::placeholder {
  color: #c0c0c0;
}

.react-stars {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  margin-bottom: 5px;
}

.cards_table {
  width: 100%;
  text-align: justify;
}

.cards_table thead {
  background: #c3c3c3;
}

table,
th,
td {
  padding: 10px;
  border: 1px solid #c3c3c3;
  border-collapse: collapse;
}

td {
  padding: 8px;
}

.cards_table td span {
  cursor: pointer;
}

.cards_table td span:first-child {
  color: #007eff;
}

.cards_table td span:last-child {
  color: red;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.d-flex{
  display: flex;
}

.section{
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.bWhite{
  background: white;
}
.bLightBlue{
  background: #f5f8fe;
}