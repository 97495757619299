
.hiwChild1{
    width: 24%;
    padding: 2%;
    margin: 5px;
    border-radius: 16px;
}

.hiwNumber{
    
    font-style: normal;
   
    line-height: 34px; /* 85% */
    text-transform: capitalize;
}
.hiwText{
   
    font-style: normal;
    
   /* 150% */
    text-transform: capitalize;
}

.hiwNumberRTL{
    
    font-style: normal;
   
     /* 85% */
    text-transform: capitalize;
    
}
.hiwTextRTL{
   
    font-style: normal;
    
    line-height: 24px; /* 150% */
    text-transform: capitalize;
    text-align: right;
}

@media screen and (max-width: 1024px) {
    .hiwChild1{
        width: 30%;
        padding: 2%;
        margin: 10px;
    }
}
@media screen and (max-width: 900px) {
    .hiwChild1{
        width: 45%;
        padding: 3%;
    }
}

@media screen and (max-width: 575px) {
    .hiwChild1{
        width: 80%;
        padding: 5%;
        margin: 10px;
    }
}