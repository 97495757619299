.profile_page_cont {
  width: 100%;
  height: 100%;
  font-weight: 500;
}

.profile_form.pt {
  position: relative;
  padding-top: 9rem;
}

.profile_cover_wrapper {
  position: absolute;
  border-radius: 6px;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
}

.profile_cover_wrapper img {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.profile_link {
  text-align: start;
  font-size: 14px;
}

.profile_page_cont .title_3 {
  margin-bottom: 8px;
  font-weight: 600;
}

.profile_link a {
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  cursor: pointer;
}

.profile_link img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile_form .profile_picture {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

.profile_form .profile_picture label {
  position: relative;
  cursor: pointer;
}

.profile_form .profile_picture label > img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}

.profile_form .profile_picture label span {
  position: absolute;
  right: 0;
  bottom: 0;
}

.profile_form .profile_picture input {
  display: none;
}

.profile_input_wrapper {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
}

.profile_input_wrapper .c_input_wrapper {
  width: 49%;
}

@media screen and (max-width: 768px) {
  .profile_input_wrapper .c_input_wrapper {
    width: 100%;
  }

  .profile_input_wrapper {
    flex-direction: column;
  }
}
