.hfParent{
    display: flex;
    width: 100%;
    background: var(--5842-bc, #5842BC);
    padding: 20px 80px;
    height: 70px;
}
.hfParentRTL{
    display: flex;
    width: 100%;
    background: var(--5842-bc, #5842BC);
    padding: 20px 80px;
    height: 70px;
    flex-direction: row-reverse;
}
.hfChild1{
    width: 80%;
    /*background: lightblue;*/
}
.hfChild1RTL{
    width: 80%;
}
.hfChild2{
    width: 20%;
    /*background: lightcoral;*/
}
.hfChild2RTL{
    width: 20%;
}
.hfTextCW{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: white;
    padding-top: 0.5%;
}
.hfTextCWRTL{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: white;
    padding-top: 0.5%;
    text-align: right;
}
.hfFooterItems{
    list-style: none;
    justify-content: space-around;
}



@media screen and (max-width: 575px) {
    .hfParent{
        flex-direction: column;
        height: 120px;
    }
    .hfParentRTL{
        flex-direction: column;
        height: 120px;
    }
    .hfChild1{
        width: 100%;
        text-align: center;
    }
    .hfChild2{
        width: 100%;
    }
    .hfChild1RTL{
        width: 100%;
        text-align: center;
    }
    .hfChild2RTL{
        width: 100%;
    }
    .hfTextCW{
        margin-bottom: 5%;
    }
    .hfTextCWRTL{
        margin-bottom: 5%;
        text-align: center;
    }
}


@media screen and (max-width: 400px) {
    .hfParent{
        height: 110px;
    }
}