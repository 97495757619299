.hpBanner{
    background-image: url("../../assets/homepage/banner.png");
    height: 600px;
    position: relative;
    padding-top: 3%;
}
.bannerSlideImg1{
    position: absolute;
    bottom: -40px;
    width: 100%;
    left: 0;
    z-index: 1;
}
.bannerSlideImg2{
    position: absolute;
    bottom: -100px;
    width: 100%;
    left: 0;
    z-index: 1;
}
.hbParent{
    display: flex;
}
.hbParentRTL{
    display: flex;
    flex-direction: row-reverse;
}
.hbChild1{
    width: 50%;
    padding: 3%;
    /*background: rebeccapurple;*/
}
.hbHead{
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; /* 125% */
    text-transform: capitalize;
}
.hbHeadRTL{
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; /* 125% */
    text-transform: capitalize;
    text-align: right;
}
.bannerMob{
    position: absolute;
    width: 500px;
    height: 580px;
    right: 50px;
    top: 50px;
    z-index: 2;
}
.bannerMobRTL{
    position: absolute;
    width: 500px;
    height: 580px;
    left: 50px;
    top: 50px;
    z-index: 2;
}
.hbDesc{
    margin-bottom: 3%;
}
.hbDescRTL{
    margin-bottom: 3%;
    text-align: right;
}
.hbCheck{
    margin-right: 3%;
}
.hbBtn{
    margin-top: 8%;
    padding: 2%;
    width: 35%;
    outline: none;
    border: 2px solid #5842bc;
    color: white;
    border-radius: 5px;
    background: #5842bc;
    cursor: pointer;
}
.hbCheckDiv{
    display: flex;
}
.hbCheckDivRTL{
    display: flex;
    flex-direction: row-reverse;
}
.gbGQBtn{
    text-align: left;
}
.gbGQBtnRTL{
    text-align: right;
}

@media screen and (max-width: 1024px) {
    .bannerMob{
        width: 400px;
        height: 480px;
    }
    .bannerMobRTL{
        width: 400px;
        height: 480px;
    }
    .bannerSlideImg1{
        bottom: 70px;
    }
    .bannerSlideImg2{
        bottom: 10px;
    }
    .hbHead{
        font-size: 35px;
        line-height: 50px; /* 125% */
    }
    .hbHeadRTL{
        font-size: 35px;
        line-height: 50px; /* 125% */
    }
    .hbDesc{
        margin-bottom: 3%;
        font-size: 14px;
    }
    .hbDescRTL{
        margin-bottom: 3%;
        font-size: 14px;
    }
    .hbCheck{
        font-size: 12px;
    }
}

@media screen and (max-width: 1023px) {
    .bannerSlideImg1{
        bottom: 0;
        display: none;
    }
    .bannerSlideImg2{
        bottom: -40px;
        display: none;
    }
    .bannerMob{
        display: none;
    }
    .bannerMobRTL{
       display: none;
    }
    .hbChild1{
        width: 100%;
        padding: 3%;
        /*background: rebeccapurple;*/
    }
    .hpBanner{
        height: 400px;
    }
    .hbBtn{
        width: 20%;
        padding: 1.5%;
    }

}

@media screen and (max-width: 800px) {
    .bannerSlideImg1{
        bottom: 40px;
    }
    .bannerSlideImg2{
        bottom: 0px;
    }
}

@media screen and (max-width: 575px) {
    .bannerSlideImg1{
        bottom: 80px;
    }
    .bannerSlideImg2{
        bottom: 60px;
    }
    .hbCheckDiv{
        display: flex;
        flex-direction: column;
    }
    .hbCheckDivRTL{
        display: flex;
        flex-direction: column-reverse;
        text-align: right;
    }
    .hbBtn{
        width: 30%;
        padding: 2%;
    }
}