.headWrapper {
    width: 100%;
    text-align: center;
    /*background: lightcoral;*/
    margin-bottom: 5%;
}

.headDiv {
    display: inline-block;
    /*border: 1px solid black;*/
}

.headingLineImg {
    text-align: left;
}

.headingLineImgRTL {
    text-align: right;
}

.hHead {
    font-size: 30px;
}

.collectionImage {
    width: auto;
    /*object-fit: cover;*/
    
    height: 500px;
    border-radius: 10px;
}

@media screen and (max-width: 1023px) {
    .collectionImage {
        width: 100%;
        /*object-fit: cover;*/
        height: 300px;
    }
}



@media screen and (max-width: 700px) {
    .hHead {
        font-size: 25px;
    }

    .collectionImage {
        width: 100%;
        /*object-fit: cover;*/
        height: 250px;
    }
}

@media screen and (max-width: 575px) {
    .hHead {
        font-size: 20px;
    }

    .collectionImage {
        width: 100%;
        /*object-fit: cover;*/
        height: 200px;
    }
}

@media screen and (max-width: 450px) {
    .hHead {
        font-size: 17px;
    }

    .collectionImage {
        width: 100%;
        /*object-fit: cover;*/
        height: 150px;
    }
}