.hnParent{
    display: flex;
    width: 100%;
    height: 65px;
    background: white;
}
.hnParentRTL{
    display: flex;
    width: 100%;
    height: 65px;
    background: white;
    flex-direction: row-reverse;
}
.hnChild1{
    /*background: pink;*/
    width: 20%;
    padding: 1.2%;
}
.hnChild2{
    /*background: deepskyblue;*/
    width: 60%;
    padding: 1.6%;
}
.hnChild3{
    display: flex;
    /*background: pink;*/
    width: 20%;
    padding: 0.8%;
}
.hnLanguageSelect{
    margin-left: 40%;
    height: 35px;
    /*border: none;*/
    outline: none;
    border-radius: 5px;
    margin-top: 2%;
}
.hnNavItems{
    list-style: none;
    justify-content: left;
}
.hnNavItemsRTL{
    list-style: none;
    justify-content: right;
}
.hnNavLinks{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 5%;
    cursor: pointer;
}
.hnNavLinksRTL{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-left: 5%;
    cursor: pointer;
}
.hnContactBtn{
    border-radius: 6px;
    background: #5842BC;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 8px 23px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    outline: none;
    border: none;
    color: white;
    display: block;
}


/* Responsive Nav */
.hnResponsiveWrapper{
    display: none;
    background: white;
    padding: 1%;
}
.hnResponsiveWrapperHide{
    display: none;
}
.hnResNavLinks{
    margin-bottom: 2%;
    cursor: pointer;
}
.listMenu{
    display: none;
}



@media screen and (max-width: 1024px) {
    .hnChild1{
        /*background: pink;*/
        width: 20%;
        padding: 1.5%;
    }
    .hnChild2{
        /*background: deepskyblue;*/
        width: 55%;
        padding: 2%;
    }
    .hnChild3{
        /*background: pink;*/
        width: 25%;
        padding: 1.3%;
    }
}

@media screen and (max-width: 950px) {
    .hnChild1{
        /*background: pink;*/
        width: 20%;
        padding: 1.5%;
    }
    .hnChild2{
        /*background: deepskyblue;*/
        width: 55%;
        padding: 2.2%;
    }
    .hnChild3{
        /*background: pink;*/
        width: 25%;
        padding: 1.3%;
    }
}


@media screen and (max-width: 800px) {
    .hnResponsiveWrapper{
        display: block;
    }
    .hnContactBtn{
        display: none;
        width: 50px!important;
    }
    .listMenu{
        display: block;
        font-size: 30px;
    }
    .hnNavItems{
        display: none;
    }
    .hnNavItemsRTL{
        display: none;
    }
    .hnChild1{
        width: 25%;
        padding: 2%;
    }
    .hnChild2{
        width: 55%;
        padding: 1.6%;
    }
    .hnChild3{
        width: 20%;
        padding: 1.5%;
    }
    .hnLanguageSelect{

        height: 30px;
        /*border: none;*/
        outline: none;
        border-radius: 5px;
        margin-top: 15%;
    }
}


@media screen and (max-width: 575px) {
    .hnChild1{
        width: 30%;
        padding: 2.5%;
    }
    .hnChild2{
        width: 45%;
        padding: 1.6%;
    }
    .hnChild3{
        width: 25%;
        padding: 2%;
    }
}


@media screen and (max-width: 450px) {
    .hnChild1{
        width: 40%;
        padding: 4%;
    }
    .hnChild2{
        width: 30%;
        padding: 1.6%;
    }
    .hnChild3{
        width: 30%;
        padding: 3%;
    }
}


