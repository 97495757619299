.hpParent{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.hpChild{
    width: 32%;
    margin: 5px;
    padding-top: 3%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}
.hpTitle{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
.hpPrice{
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    margin-bottom: 2%;
    margin-top: 3%;
}
.hpPriceY{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.hpDesc{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 10%;
}
.hpPDParent{
    display: flex;
    margin: 2%;
}
.hpPDParentRTL{
    display: flex;
    flex-direction: row-reverse;
    margin: 2%;
}
.hpPDChild1{
    width: 80%;
}
.hpPDChild2{
    width: 20%;
}
.hpPDesc{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.hpPDescRTL{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: right;
}
.hpPDIcon{
    font-size: 18px;
}
.checkIcon{
    color: #1a3aed;
}
.hpBtn{
    margin-top: 10%;
    padding: 2%;
    width: 40%;
    outline: none;
    border: 2px solid #1a3aed;
    color: #1a3aed;
    border-radius: 5px;
    background: white;
    cursor: pointer;
}
.hpBtn:hover{
    margin-top: 10%;
    padding: 2%;
    width: 40%;
    outline: none;
    border: 2px solid #1a3aed;
    color: white;
    border-radius: 5px;
    background: #1a3aed;
    cursor: pointer;
}



@media screen and (max-width: 1023px) {
    .hpChild{
        width: 45%;
    }
}

@media screen and (max-width: 850px) {
    .hpChild{
        width: 60%;
        padding-top: 5%;
        padding-bottom: 4%;
        padding-left: 2%;
        padding-right: 2%;
    }
}
@media screen and (max-width: 650px) {
    .hpChild{
        width: 70%;
    }
}
@media screen and (max-width: 575px) {
    .hpChild{
        width: 100%;
        padding-top: 7%;
        padding-bottom: 6%;
        padding-left: 2%;
        padding-right: 2%;
    }
}

